import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { createTheme } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import '@fontsource/montserrat'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/montserrat/700.css'

const theme = {
  typography: {
    fontFamily: ['Montserrat', 'Roboto', '"Helvetica Neue"'],
    fontSize: 13,
    body2: {
      fontWeight: 500,
    },
  },
}
const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={createTheme(theme)}>
    <CssBaseline />
    {element}
  </ThemeProvider>
)

export default wrapRootElement
